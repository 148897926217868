import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/How_To_Clear_Your_Browsing_History/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Erase your Browsing History in Internet Explorer",
  "path": "/Quick_Installation/How_To_Clear_Your_Browsing_History/Internet_Explorer/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Erase your Browsing History in Internet Explorer",
  "image": "./QI_SearchThumb_ClearBrowsingHistory_IE.png",
  "social": "/images/Search/QI_SearchThumb_ClearBrowsingHistory_IE.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Clear_History_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Clear your Browser History' dateChanged='2017-11-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='Clear your Browser History' image='/images/Search/QI_SearchThumb_ClearBrowsingHistory.png' twitter='/images/Search/QI_SearchThumb_ClearBrowsingHistory.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Leeren_des_Browserverlaufs/Internet_Explorer/' locationFR='/fr/Quick_Installation/How_To_Clear_Your_Browsing_History/Internet_Explorer/' crumbLabel="Internet Explorer" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "erase-your-browsing-history-in-internet-explorer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#erase-your-browsing-history-in-internet-explorer",
        "aria-label": "erase your browsing history in internet explorer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Erase your Browsing History in Internet Explorer`}</h1>
    <p>{`Sometimes your camera´s WebUI might not behave the way you want. You updated your camera´s software, but it still shows you the old version? An update should bring new functions to the user interface - but they are nowhere to be seen? Or you simply cannot change a setting? Your Browser might have cached old parts of the user interface and refuses to reload the new or updated files. Here is how you can get rid of your browsers browsing history in several common browsers:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7642aeb2924a36637f272423d6d8f5f7/5a190/InternetExplorer-Clean-History_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA30lEQVQY012PQU7DMBBFc/8jsEAsuAArWHAGJERVSqCldsZjOzEEJ+n6oTiAShZPMx79mf9dHY1FVImxJYRYUO+xIsS24+OzR8ThnKLqcWeoaiEEXzRv+1cq6yJWWxptEd/hQiq9kfg3K5oVxkVEI16Vvu/JOdOIUM1Ls2CuB+PYH2V5l8WA8x0aExr+Mxv7NmGsLWnzOGIb+Uk4u/mOp23N4+a5OMc2FfIwMYynFRPDdOKrzxx2L7zXNTklxJgl4W/K+aj45cvnc7PCSsCEhGxrNhdXPFxe09zcYW7v+QaMMHAcfQmegQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7642aeb2924a36637f272423d6d8f5f7/e4706/InternetExplorer-Clean-History_01.avif 230w", "/en/static/7642aeb2924a36637f272423d6d8f5f7/d1af7/InternetExplorer-Clean-History_01.avif 460w", "/en/static/7642aeb2924a36637f272423d6d8f5f7/c9875/InternetExplorer-Clean-History_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7642aeb2924a36637f272423d6d8f5f7/a0b58/InternetExplorer-Clean-History_01.webp 230w", "/en/static/7642aeb2924a36637f272423d6d8f5f7/bc10c/InternetExplorer-Clean-History_01.webp 460w", "/en/static/7642aeb2924a36637f272423d6d8f5f7/d00b9/InternetExplorer-Clean-History_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7642aeb2924a36637f272423d6d8f5f7/81c8e/InternetExplorer-Clean-History_01.png 230w", "/en/static/7642aeb2924a36637f272423d6d8f5f7/08a84/InternetExplorer-Clean-History_01.png 460w", "/en/static/7642aeb2924a36637f272423d6d8f5f7/5a190/InternetExplorer-Clean-History_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7642aeb2924a36637f272423d6d8f5f7/5a190/InternetExplorer-Clean-History_01.png",
              "alt": "Erase your Browsing History in Internet Explorer",
              "title": "Erase your Browsing History in Internet Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the cog wheel icon in the top right corner and choose Internet Options.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/05c0c00636b541bf5e4179bfbf668235/5a190/InternetExplorer-Clean-History_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABaklEQVQoz32RyW7bMBBA9f8/lkvTOAcHjWvtFElxGw6XoWQfEjAugjoNSrzDgODDG4DN6fTa933XdefzuW3b48vx1+tpZqxtu6fD4fD8/C2PP58efzw0XGgACCEixpyzVGZgq1BukWZc1ukf5htc9ePUXC5X7z1i8B9nVXqYOONqEZpL84WZV60idDfMTU4phFhK2ff9er1Y5xYhHWDKJSb6m5RIKj19ZP/INYohprzvO1FW2o5MMqGnb3e+ZT/loe21Md46BKCctXEDk4vUTFRmrm4Duw1f5NR217e3ePrtj0cEwBBSTBiSx1ioOMCYckoZPBIRhnQng3PG2lVprbSU0lpLRLH6cd838CFnIiLAsG1bjPfyvMiRiXbk88KnmTEuPUZjvbHgMSoDDtABKuPAB+v8nTxxNS2Kr6aUQlTAh1K2EGp5K5vzIWXKuZbr/We5/qVq5iqvXBqiUtfzuG310f/XnhYllHkHeCFbiR6rUGsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05c0c00636b541bf5e4179bfbf668235/e4706/InternetExplorer-Clean-History_02.avif 230w", "/en/static/05c0c00636b541bf5e4179bfbf668235/d1af7/InternetExplorer-Clean-History_02.avif 460w", "/en/static/05c0c00636b541bf5e4179bfbf668235/c9875/InternetExplorer-Clean-History_02.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/05c0c00636b541bf5e4179bfbf668235/a0b58/InternetExplorer-Clean-History_02.webp 230w", "/en/static/05c0c00636b541bf5e4179bfbf668235/bc10c/InternetExplorer-Clean-History_02.webp 460w", "/en/static/05c0c00636b541bf5e4179bfbf668235/d00b9/InternetExplorer-Clean-History_02.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05c0c00636b541bf5e4179bfbf668235/81c8e/InternetExplorer-Clean-History_02.png 230w", "/en/static/05c0c00636b541bf5e4179bfbf668235/08a84/InternetExplorer-Clean-History_02.png 460w", "/en/static/05c0c00636b541bf5e4179bfbf668235/5a190/InternetExplorer-Clean-History_02.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/05c0c00636b541bf5e4179bfbf668235/5a190/InternetExplorer-Clean-History_02.png",
              "alt": "Erase your Browsing History in Internet Explorer",
              "title": "Erase your Browsing History in Internet Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the General Tab go to Browsing History and check Clear Browsing History on Exit. Then click on Delete to open the Deletion Dialogue.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9a011400978d4aac292b9fac5f366580/5a190/InternetExplorer-Clean-History_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABcElEQVQoz32Q2W7bMBBF9f8/ltpNQ1kGWiqWZZMUd0rcxCVvgRK3gIK2FwcD8OFg7rABAJy7c9/34zgOw9CeTv3rhRAyDFcA2vZ0+isvL+DH81MDIfy55RdCSCmFCCVcc7VQYTCV/4IwdUO4aUHbdR2E8H7fZEwomgSmknJNhfkCYeoB1+OdNAAACCFCSAihtaGMC2UW69dcYspfEMp8rn3IEEKMsTHGfQRPlDA9cYXZDvJ77jZDCClljNF5nr13mFDC5MTVB5qwbW6wx3MnHw+H4+HwHYD2fL4Og9Ymp+x8dD6WXBbr45rWNS3O51x8iDt5vKHhers8feuPzyMiUspaa4gxhPhWq3UhpZxztj7UWmNcd/KA5UgUaTvxesGUUy5diGaxZrEuRD3bxfnFeWWs9WFe3E7GVKCJT2pOtaa01atlq+dDrKVY59e0xbpQSgkx/jl74rLZvpFKJk0pJeVsXXjbaq8hrP+pjaniyrwD+JpS6WrCmQ4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a011400978d4aac292b9fac5f366580/e4706/InternetExplorer-Clean-History_03.avif 230w", "/en/static/9a011400978d4aac292b9fac5f366580/d1af7/InternetExplorer-Clean-History_03.avif 460w", "/en/static/9a011400978d4aac292b9fac5f366580/c9875/InternetExplorer-Clean-History_03.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9a011400978d4aac292b9fac5f366580/a0b58/InternetExplorer-Clean-History_03.webp 230w", "/en/static/9a011400978d4aac292b9fac5f366580/bc10c/InternetExplorer-Clean-History_03.webp 460w", "/en/static/9a011400978d4aac292b9fac5f366580/d00b9/InternetExplorer-Clean-History_03.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a011400978d4aac292b9fac5f366580/81c8e/InternetExplorer-Clean-History_03.png 230w", "/en/static/9a011400978d4aac292b9fac5f366580/08a84/InternetExplorer-Clean-History_03.png 460w", "/en/static/9a011400978d4aac292b9fac5f366580/5a190/InternetExplorer-Clean-History_03.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9a011400978d4aac292b9fac5f366580/5a190/InternetExplorer-Clean-History_03.png",
              "alt": "Erase your Browsing History in Internet Explorer",
              "title": "Erase your Browsing History in Internet Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adjust the elements you want to delete (see above) and click on Delete.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/5a190/InternetExplorer-Clean-History_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABSElEQVQoz2WQ2U7DMBBF+///BVRCSERBgjZqk3pfEo/3msIDShNoUqzzYI995treIEKEkDNcYkIp42YAKRXGhDHBGP8PQqRrdxvEFOGacE1FT7hGVGIqmRyuc4GZXKN+0d0JbaYForJFDJHbOSrGdncs/WNHRpmKvjmil9f60GKlByH1YCCmHGJaElMScoyZkmd5Sp6YnrC43orF1lreNe2hw0uT8BvTcvnmWUZUUaFjjCHGAWzK2fpgnU85G3DhWjfWpZysD7PM13K+DrC+lBJC9D58lgLXFmPd+VLOIUR0J49XEto4b5zXBmwIvXU9OBeiNhach6nuw2Ad5nfJTFGisovZRdC2hByMtxqKi0aZCD6BN8qcXXS9PSHx9yNzsqofYf8I+6f+4wGarW22hVTf/K2Q6kLrC6sLqb5YfWEVvD8jOipcDj+Colhl5UOsDwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/e4706/InternetExplorer-Clean-History_04.avif 230w", "/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/d1af7/InternetExplorer-Clean-History_04.avif 460w", "/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/c9875/InternetExplorer-Clean-History_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/a0b58/InternetExplorer-Clean-History_04.webp 230w", "/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/bc10c/InternetExplorer-Clean-History_04.webp 460w", "/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/d00b9/InternetExplorer-Clean-History_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/81c8e/InternetExplorer-Clean-History_04.png 230w", "/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/08a84/InternetExplorer-Clean-History_04.png 460w", "/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/5a190/InternetExplorer-Clean-History_04.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2a2720e0a859eebed4cf5bad5b1d49c4/5a190/InternetExplorer-Clean-History_04.png",
              "alt": "Erase your Browsing History in Internet Explorer",
              "title": "Erase your Browsing History in Internet Explorer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Wait for the Confirmation, restart your Browser and reload your camera's user interface.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      